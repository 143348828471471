import React from "react";
import {useTranslation} from "react-i18next";

const ThankYou = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const handelClick = () => {
        window.location.reload();
    }

    return (
        <div className={'ms-container'}>

            <div
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: "column",
                    gap: '20px',
                    height: '100vh'
                }}
            >
                <h1 className="ms-title">{t("thank.you.for.your.order")}</h1>

                <button type={'button'} className="ms-link" onClick={handelClick}>{t("back.to.the.homepage")}</button>
            </div>
        </div>
    )
}

export default ThankYou;