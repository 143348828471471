import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {List, ListItem, Typography} from "@mui/material";

const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(() => localStorage.getItem("language") || i18n.language);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    const handleChange = (value: string) => {
        setLanguage(value);
        i18n.changeLanguage(value);
        localStorage.setItem("language", value);
    };

    return (
        <List sx={{display: 'flex', gap: '5px', color: "#74c92c", marginBottom: '1rem'}}>
            <ListItem
                sx={{display: "contents", cursor: "pointer"}}
                onClick={() => handleChange("en")}
            >
                <Typography sx={{fontWeight: '600', textDecoration: `${language === "fi" ? "none" : "underline"}`, fontSize: '17px'}}>en</Typography>
            </ListItem>
            <ListItem
               sx={{display: "contents"}}
            >
                <Typography sx={{fontWeight: '600', fontSize: '17px'}}>/</Typography>
            </ListItem>
            <ListItem
                sx={{display: "contents"}}
                onClick={() => handleChange("fi")}
                className="language-switch__item"
            >
                <Typography sx={{fontWeight: '600', textDecoration: `${language === "fi" ? "underline" : "none"}`, fontSize: '17px'}}>fi</Typography>
            </ListItem>
        </List >
    );
};

export default LanguageSwitcher;
