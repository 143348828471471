const fi ={
    "service.details": "Palvelun tiedot",
    "service": "Palvelu",
    "product": "Tuote",
    "region": "Alue",
    "street.address.where.starts": "Katuosoite mistä muutto alkaa",
    "street.address.where.ends": "Katuosoite mihin muutetaan",
    "rental.start.date": "Vuokrauksen alkamispäivä",
    "continue": "Eteenpäin",
    "unfortunately": "Valitettavasti valitsemananne aikana ei ole saatavilla palveluitamme. Pahoittelemme mahdollista hankaluutta. " +
        "Voitte ystävällisesti tarkistaa muita aikoja varaussivullamme, jotta löydätte sopivan vaihtoehdon. Kiitos kärsivällisyydestänne " +
        "ja ymmärryksestänne",
    "select.transport": "Valitse kuljetus",
    "select.a.start.time": "Valitse alkamisaika - se on pakollinen tieto",
    "select.a.start.schedule": "Valitse aloitusaikataulu",
    "go.back": "Taaksenpäin",
    "personal.information": "Henkilökohtaiset tiedot",
    "first.name": "Etunimi",
    "last.name": "Sukunimi",
    "email": "Email",
    "phone.number": "Puhelinnumero",
    "order.details": "Tilauksen tiedot",
    "moving.date": "Muuttopäivä",
    "start.time": "Aloitusaika",
    "discounted.price": "Alennushinta",
    "amount.to.be.paid": "Maksettava summa",
    "house.number.staircase.door.number": "Talon numero, rappu ja ovinumero",
    "additional.information": "Lisätietoa (Esim. ovikoodi)",
    "moving.from": "Mistä muutetaan",
    "enter.the.address.where.the.move.starts": "Anna osoite, mistä muutto alkaa. Näet saatavilla olevat muuttopäivät ja tarkennetun hinnan osoitteiden syöttämisen jälkeen",
    "apartment.size": "Asunnon pinta-ala (m2)",
    "select.floor": "Valitse kerros",
    "ground.level": "Maa-taso",
    "row.house.1st.floor": "Rivitalo 1 kerros",
    "detached.house.1st.floor": "Omakotitalo 1 kerros",
    "row.house.2nd.floor": "Rivitalo 2 kerros",
    "detached.house.2nd.floor": "Omakotitalo 2 kerros",
    "floor": "Kerros",
    "Varasto": "Kuinka lähelle muuttoautolla pääsee (m)",
    "elevator.size": "Hissin koko",
    "no.elevator": "Ei hissiä",
    "small.elevator": "Pieni hissi (alle 1m2)",
    "large.elevator": "Iso hisi (1m2 tai isompi)",
    "new.construction": "Uudiskohde",
    "storage": "Varasto",
    "no.storage": "Varastoa ei ole",
    "basement.room.outdoor.storage": "Kellarikomero / Ulkovarasto",
    "attic.loft": "Vintti / Ullakko",
    "storage.size": "Varaston koko (m2)",
    "storage.floor": "Varaston kerros",
    "where.are.you.moving.to": "Mihin muutetaan",
    "use.coupon": "Käytä kuponki",
    "use": "Käyttää",
    "used.coupon": "Käytetty kuponki",
    "cancel": "Peruuttaa",
    "will.pay.time.move": "Maksan muuton yhteydessä",
    "thank.you.for.your.order": "Kiitos tilauksestasi",
    "back.to.the.homepage": "Takaisin kotisivulle",
}

export default fi;