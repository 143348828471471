const en ={
    "service.details": "Service details",
    "service": "Service",
    "product": "Product",
    "region": "Region",
    "street.address.where.starts": "Street address of the move’s starting location",
    "street.address.where.ends": "Street address of the destination",
    "rental.start.date": "Rental start date",
    "continue": "Continue ",
    "unfortunately": "Unfortunately, our services are not available at the selected time. We apologize for any inconvenience. " +
        "Please kindly check other available times on our booking page to find a suitable option. " +
        "Thank you for your patience and understanding",
    "select.transport": "Select transport",
    "select.a.start.time": "Select a start time – this is a required field",
    "select.a.start.schedule": "Select a start schedule",
    "go.back": "Go back",
    "personal.information": "Personal information",
    "first.name": "First name",
    "last.name": "Last name",
    "email": "Email",
    "phone.number": "Phone number",
    "order.details": "Order details",
    "moving.date": "Moving date",
    "start.time": "Start time",
    "discounted.price": "Discounted price",
    "amount.to.be.paid": "Amount to be paid",
    "house.number.staircase.door.number": "House number, staircase, and door number",
    "additional.information": "Additional information (e.g., door code)",
    "moving.from": "Moving from",
    "enter.the.address.where.the.move.starts": "Enter the address where the move starts. You will see the available moving dates and the detailed price after entering the addresses",
    "apartment.size": "Apartment size (m2)",
    "select.floor": "Select floor",
    "ground.level": "Ground level",
    "row.house.1st.floor": "Row house 1st floor",
    "detached.house.1st.floor": "Detached house 1st floor",
    "row.house.2nd.floor": "Row house 2nd floor",
    "detached.house.2nd.floor": "Detached house 2nd floor",
    "floor": "Floor",
    "how.close.can.the.moving.truck.get": "How close can the moving truck get (m)",
    "elevator.size": "Elevator size",
    "no.elevator": "No elevator",
    "small.elevator": "Small elevator (less than 1m2)",
    "large.elevator": "Large elevator (1m2 or larger)",
    "new.construction": "New construction",
    "storage": "Storage",
    "no.storage": "No storage",
    "basement.room.outdoor.storage": "Basement room / Outdoor storage",
    "attic.loft": "Attic / Loft",
    "storage.size": "Storage size (m2)",
    "storage.floor": "Storage floor",
    "where.are.you.moving.to": "Where are you moving to",
    "use.coupon": "Use coupon",
    "use": "Use",
    "used.coupon": "Used coupon",
    "cancel": "Cancel",
    "will.pay.time.move": "I will pay at the time of the move",
    "thank.you.for.your.order": "Thank you for your order",
    "back.to.the.homepage": "Back to the homepage",
}

export default en;